.tab-dash-admin-filter-form {
  display: flex;
  align-items: center;
}
.tab-dash-admin-filter-label {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  text-align: center;

  color: rgba(0, 0, 0, 0.6);
}
.tab-dash-admin-filter-input {
  width: 200px;
  height: 39px;
  left: 377px;
  top: 223px;
  background: #f7f7f7;
  border-radius: 10px;
  border: none;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;

  color: rgba(0, 0, 0, 0.6);
}
.tab-dash-admin-filter-input::placeholder {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 16px;
  color: #252733;
}
.btn-export-info-tabs {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #828282;
  border: 1px solid #828282;
  border-radius: 5px;
  background: #fff;
}
.text-date-facture {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(51, 51, 51, 0.5);
}
.title-info-detail-facture {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
}
.text-detail-facture-libelle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #5a5a5a;
}
.text-detail-facture-value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.text-detail-facture-montant-libelle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.text-montant-total-facture {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4f9fd1;
}
.clock-icon-position {
  position: absolute;
  right: 4%;
  top: 10%;
}
.content-horaire-div {
  width: 50%;
}
.text-active-notif-bubblezone {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 50px;
  color: #010235;
}
.btn-send-bubblezone {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #252733;
  border: 1px solid #db3251;
  padding: 15px;
}
.btn-send-bubblezone:hover {
  color: #db3251;
}
.img-couverture-buubblezone-detail {
  width: 100%;
  height: 300px;
}
.text-info-detail-bubblezone {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.text-info-detail-bubblezone-theme {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 16px;
  color: #db3251;
}
.content-mood-detail-bubblezone {
  display: flex;
  gap: 5px;
  align-items: center;
}
.pourcentage-mood-detail-bubblezone {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17.1462px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #000000;
}
.mood-img-detail-bubblezone {
  width: 30px;
  height: 30px;
}
.pourcentage-jeton-detail-bubblezone {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36.6667px;
  line-height: 55px;
  color: #030303;
}
.prix-jeton-detail-bubblezone {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36.6667px;
  line-height: 55px;
  color: #db3251;
}
.notes-labels-container {
  flex-wrap: wrap;
}
.date-filter-details-bubble-zone-container {
  width: 25%;
}
.notification-cloche-icon {
  font-size: 25px;
}
.custom-btn-upload {
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
  color: #531132;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-btn-upload:hover {
  border: 1px solid #db3251;
  color: #db3251;
}
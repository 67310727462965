.btn-parcourir {
	background: #f4f4f4;
	border: 1px solid #f4f4f4;
	border-radius: 10px;
	font-family: "Raleway";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: #000000;
	padding: 13px 18px;
	display: flex;
	gap: 7px;
	cursor: pointer;
}
.card-admin-knowledge {
	background: #ffffff;
	border-radius: 10px;
}
.admin-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 26px;
	line-height: 39px;
	color: #011f3b;
}
.open-modal-btn {
	border: 1px solid rgba(167, 70, 15, 0.9);
	border-radius: 5px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: #5e5b5b;
	opacity: 0.75;
	padding: 0.7rem 1.3rem;
	display: flex;
	align-items: center;
	gap: 10px;
	text-transform: uppercase;
}
.open-modal-btn svg {
	width: 18px;
	height: 18px;
	color: rgba(167, 70, 15, 0.9);
	font-weight: 900;
}
.auth-admin-submit-btn {
	background: #b45a2e;
	border: 2px solid #b45a2e;
	border-radius: 5px;
	padding: 0.7rem 1.3rem;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	color: #ffffff !important;
	text-transform: uppercase;
}
.auth-admin-submit-btn:hover {
	background: #fff !important;
	border: 2px solid #b45a2e;
	color: #b45a2e !important;
	text-decoration: none;
}
.admin-edit-btn {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: rgba(167, 70, 15, 0.9);
	opacity: 0.75;
	text-transform: uppercase;
	display: flex;
	align-items: center;
}
.admin-modal-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 30px;
	color: #011f3b;
	text-align: center;
	width: 100%;
}
.close-modal {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 26px;
	color: #000000;
}
.custom-admin-form-control {
	background: #f2f3f3;
	border-radius: 5px;
	padding: 10px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #1b1b1b;
}

.custom-proprietaire-filter-input {
  width: 315px;
  height: 39px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;

  color: rgba(0, 0, 0, 0.6);

  border: 1px solid #ccd2d8;
  border-radius: 5px;
  padding: 12px 15px 12px 35px;
}
.custom-proprietaire-filter-input::placeholder {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8c8ca2;
}
.custom-proprietaire-filter-input-container {
  position: relative;
}
.custom-proprietaire-filter-input-icon-container {
  position: absolute;
  top: 6px;
  left: 8px;
}
.custom-proprietaire-filter-input-icon {
  color: #8c8ca2;
  font-size: 18px;
}

.custom-filter-input-btn-add-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-btn-add-theme-white {
  background: #fff;
  border-radius: 10px;
  color: #db3251;
  font-family: "DM Sans";
  font-size: 13.157px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}
.custom-btn-add-theme {
  background: #db3251;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 0.5rem 1rem;
}
.custom-btn-add-theme:hover {
  border: 1px solid #db3251;
  color: #db3251;
  background: #fff;
}
.custom-btn-add-theme-upgrade {
  background: #DB32513D;
  /* background: #db3251; */
  font-family: "DM Sans";
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;

  border-radius: 10px;
  font-style: normal;
  display: flex;
  align-items: center;
  color: #DB3251;
  padding: 0.5rem 1rem;
}
.custom-btn-add-theme-upgrade:hover {
  border: 1px solid #DB32513D;
  color: #DB3251;
  background: #fff;
}
.custom-btn-add-theme-white:hover {
  border: 1px solid #fff;
  color: #fff;
  background: #db3251;
}

.custom-photo-bubblezone-table {
  width: 41px;
  height: 41px;
  border-radius: 5px;
}
.custom-btn-action-bubble-modif {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  background: #db3251;
  border-radius: 100px;
  padding: 3px 15px;
}
.custom-btn-action-bubble-see {
  color: #373737;
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #d7d7d7;
  border-radius: 100px;
  padding: 3px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-btn-action-bubble-download {
  color: #666666;
  font-size: 20px;
  background: none;
  border: none;
  padding: 0px;
}

.bubblezone-page-title-container {
  display: flex;
  align-items: center;
  gap: 15px;
}
.bubblezone-page-title-btn-back {
  background: none;
  border: none;
}
.bubblezone-page-title-btn-back-icon {
  color: #db3251;
  font-size: 20px;
}
.bubblezone-page-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
  color: #2d3748;
}
.bubblezone-ajout-bubble-form {
  width: 75%;
  margin: auto;
}
.bubblezone-ajout-bubble-form-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  height: 65%;
}
.bubblezone-add-bubblezone-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8.37391px 15.8174px;
  gap: 9.3px;
  height: 36.75px;
  color: #fff;
  background-color: #333333;
  border: 1px solid #db3251;
  border-radius: 37.2174px;
  cursor: pointer;
}
.bubblezone-add-bubblezone-type:hover,
.bubblezone-add-bubblezone-type.active {
  background: #db3251;
}

.bubblezone-add-bubblezone-type.disabled {
  background-color: #eee;
  cursor: not-allowed;
  color: #333333;
}

.bubblezone-add-bubblezone-type-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
.input-infos-container {
  position: relative;
}
.input-infos-icon-container {
  position: absolute;

  right: -25px;
  top: 30px;
}
.input-infos-icon {
  color: #000000;
  font-size: 25px;
}
.bubblezone-heure-ouverture-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.3);
}
.bubblezone-heure-ouverture-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: auto;
}
.add-bubble-heure-dispo-container {
  background: #ffffff;
  border: 0.483915px solid #d9d6d6;
  border-radius: 3.87132px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13.5496px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  padding: 0px 2px;
}
.add-bubble-heure-dispo-container span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12.5818px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
}
.add-bubble-custom-form-check-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.5175px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.8);
}
.form-check-input:checked {
  color: rgba(102, 102, 102, 0.7) !important;
  background-color: #db3251 !important;
  border-color: #db3251 !important;
}
.edit-bubble-code-span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.7);
}
.custom-width-proprietaire-filter-input {
  width: 500px;
}
.proprietaire-detail-bubblezone-btn-action-container {
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: auto;
}
.proprietaire-detail-bubblezone-btn-filter {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-family: "DM Sans";
  border-radius: 10px;
  border: 1px solid #b1b1b1;
  background: #f6f6f6;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.proprietaire-detail-bubblezone-btn-filter.active {
  border-radius: 10px;
  border: 1.5px solid #db3251;
  background: #f6eff3;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-family: "DM Sans";
}
.proprietaire-detail-bubblezone-btn-filter-icon {
  color: #909090;
  font-size: 25px;
}
.proprietaire-detail-bubblezone-card-graph-container {
  border-radius: 6px;
  border: 0.5px solid #d9d9d9;
  background: #fff;
  padding: 15px;
  min-height: 325px;
}
.proprietaire-detail-bubblezone-card-graph-title {
  color: #828282;
  font-size: 14px;
  font-weight: 700;
  font-family: "Poppins";
  line-height: 140%;
}
.custom-graph-container {
  margin: auto;
}
.proprietaire-detail-bubblezone-card-thumbs-container {
  display: flex;
  align-items: center;
  gap: 12px;
}
.proprietaire-detail-bubblezone-card-thumbs-container span {
  color: #333;
  font-size: 32px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 32.11px;
}
.proprietaire-detail-bubblezone-card-item-thumbs-container {
  color: rgba(51, 51, 51, 0.8);
  font-size: 16.086px;
  font-family: Poppins;
  line-height: 14.645px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.proprietaire-detail-bubblezone-card-thumbs-up {
  color: #14b8ae;
  font-size: 28px;
}
.proprietaire-detail-bubblezone-card-thumbs-down {
  color: #db3251;
  font-size: 28px;
}
.proprietaire-detail-bubblezone-card-avis-item-container {
  display: flex;
  align-items: center;
  gap: 30px;
}
.proprietaire-detail-bubblezone-card-item-thumbs-up {
  color: #14b8ae;
  font-size: 22px;
}
.proprietaire-detail-bubblezone-card-item-thumbs-down {
  color: #db3251;
  font-size: 22px;
}

.custom-bubble-zone-dash-header-titre {
  color: #3b4758;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 700;
}
.proprietaire-detail-bubblezone-username {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: "Work Sans";
  font-weight: 500;
}
.proprietaire-detail-bubblezone-date-commentaire {
  color: #636b78;
  font-size: 14px;
  font-family: "Work Sans";
}
.proprietaire-detail-bubblezone-username-commentaire-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.proprietaire-detail-bubblezone-username-commentaire {
  display: flex;
  align-items: center;
  gap: 10px;
}
.proprietaire-detail-bubblezone-commentaire-text {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-family: Work Sans;
  font-weight: 300;
}
.custom-commentaire-list-group-item {
  display: flex;
  gap: 10px;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
.proprietaire-detail-bubblezone-useravatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.list-group-btn-supp-container {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 15px;
}
.list-group-btn-supp {
  color: #bcbcbc;
  font-size: 24px;
}
.message-communication-title {
  color: #000;
  font-family: "Poppins";
  font-size: 20px;
  /* font-style: italic; */
  font-weight: 700;
  line-height: 36px;
  display: flex;
  flex-direction: column;
}
.message-communication-title span {
  font-weight: 300;
}
.message-communication-wrapper {
  border-radius: 10px;
  /* background: #e6e6e6; */
  background: #FBFBFB;
}
.message-communication-label {
  color: #06152b;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.message-communication-downloadable-container {
  /* background: #d9d9d9; */
  background: #D9D9D936;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message-communication-downloadable-title {
  color: #000;
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.message-communication-downloadable-size {
  color: #000;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.message-communication-downloadable-btn {
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.auth-submit-btn-preview-short {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 18.23px;
  text-align: left;
  background: #FFF;
  color: #DB3251;
  border: 1px solid #DB3251;
  box-shadow: 0px 4px 17px 0px #0000001A;
  padding: 16px 58px;
  gap: 10px;
  border-radius: 10px;
}
.custom-dialog-preview .modal-content {
  /* background: #ffffffa3; */
  background: none;
  border: none;
  position: relative;
}
.custom-preview-btn-close, .custom-preview-btn-close:hover {
  position: absolute;
  top: 20px;
  right: 20px;
  gap: 5px;
  padding: 8px;
  border-radius: 25px;
  background: #DB3251;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-previem-modal-body {
  display: flex;
  justify-content: center;
}
.preview-mockup-main-screen {
  width: 100%;
  height: 100%;
  background: #000000;
  box-shadow: 5px 5px 25px 0px #00000026;
}
.preview-mockup-screen-bubblezone-img-container {
  width: 100%;
  height: 254px;
  border-radius: 0px 0px 30px 30px;
  background: #FFFFFF2B;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.preview-mockup-screen-bubblezone-btn-previeous {
  padding: 0px;
  width: 26px;
  height: 26px;
  top: 68.33px;
  left: 16.07px;
  gap: 0px;
  border-radius: 15px;
  opacity: 0px;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-mockup-screen-bubblezone-btn-action-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 40px;
  padding-left: 10px;
}
.preview-mockup-screen-bubblezone-mood-container {
  width: 66.42px;
  height: 66.42px;
  top: 41.79px;
  left: 308.58px;
  padding: 5.54px 5.54px 5.53px 5.54px;
  gap: 0px;
  border-radius: 27.68px 0px 0px 27.68px;
  border-width: 1px, 0px, 1px, 1px;
  border: 1px solid #DB3251;
  opacity: 0px;
  background: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-mockup-screen-bubblezone-titre {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #FFFFFF;
}
.preview-mockup-screen-bubblezone-btn-social-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.preview-mockup-screen-bubblezone-btn-social {
  width: 48.33px;
  height: 48.33px;
  opacity: 0px;
  background: #FFFFFF26;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 8px;
}
.preview-mockup-screen-bubblezone-titre-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}
.custom-divider-color {
  color: #FFF;
}
.preview-mockup-screen-bubblezone-titre-caracteristique-container {
  padding: 20px;
}
.preview-mockup-screen-bubblezone-titre-caracteristique {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  text-align: left;
  color: #FFFFFF;
}
.custom-badge-caracteristique {
  min-width: 70px !important;
  width: auto !important;
  height: auto !important;
  padding: 6px !important;
  gap: 10px;
  background: #333333E5 !important;
  border: 2px solid #FDCA1F;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: center;
  flex-wrap: wrap;
}
.custom-badge-caracteristique-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.preview-mockup-screen-bubblezone-custom-search::placeholder {
  font-family: "Poppins";
  font-size: 11px;
  font-weight: 400;
  line-height: 15.4px;
  text-align: left;
  color: #FFFFFF;
}
.preview-mockup-screen-bubblezone-custom-search {
  width: 162px;
  border-radius: 25px;
  background: #333333;
  border: none;
  padding: 6px 35px 10px 14px;
  color: #FFF;
}
.preview-mockup-screen-bubblezone-custom-search-icon {
  position: relative;
  top: 0px;
  right: 30px;
  width: 20px;
  height: 20px;
  gap: 0px;
  opacity: 0px;
  color: #FFF;
}
.custom-container {
  position: relative;
  /* width: 50%; */
  /* max-width: 300px; */
}
.membre-bubblezone-custom-img {
  display: block;
  width: 100%;
  height: auto;
}
.custom-membre-bubblezone-overlay {
  position: absolute;
  bottom: 0;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.47%, rgba(0, 0, 0, 0.62) 91.06%);
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 1;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
.custom-membre-bubblezone-overlay-mood {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 44px;
  height: 44px;
  gap: 0px;
  border-radius: 20px;
  opacity: 0px;
  background: #06060666;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-membre-bubblezone-overlay-name-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom-membre-bubblezone-overlay-name {
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 500;
  line-height: 25.5px;
  letter-spacing: 0.01em;
  text-align: center; 
  color: #FFF; 
}
.custom-membre-bubblezone-overlay-age {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #FFF;
}
.custom-membre-bubblezone-overlay-online-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #BABABA;
  margin-top: 5px;
}
.custom-wrapper {
  overflow: auto;
  height: 370px;
}
.preview-mockup-screen-bubblezone-footer {
  width: 100%;
  height: 130px;
  border: 1px;
  background: #000;
  border-top: 1px solid #3F4866;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  position: absolute;
    bottom: -55px;
}
.preview-mockup-screen-bubblezone-footer-btn {
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actu-bubblezone-from-google-btn-plus {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #DB3251;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 0px;
}
.custom-article-border {
  border: 1px solid #0000001A;
  border-radius: 10px;
}
.col-left-dashboard.col-left-dashboard-responsive.custom {
  height: 75vh;
}
.bubble-map-container {
  height: 70vh !important;
}
*::-webkit-scrollbar {
  display: none;
}
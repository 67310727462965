.input-modal-label-form-add {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: -0.025em !important;
  color: #000000 !important;
  /* color: rgba(0, 0, 0, 0.7) !important; */
}
.input-modal-form-add {
  border: 0.25px solid #959595;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08)) !important;
  border-radius: 0px !important;
  background: none !important;
}
.input-modal-form-add::placeholder {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: -0.025em !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

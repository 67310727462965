.content-stat-detail-bubblezone {
  background: #ffffff;
  border: 1px solid #db3251;
  border-radius: 9.42171px;
  padding: 20px;
  width: 100%;
}
.text-stat-detail-bubblezone {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.9591px;
  line-height: 25px;
  color: #747474;
}
.conatiner-info-stat-bubblezone-detail {
  display: flex;
  justify-content: space-between;
}
.chiffre-stat-detail-bubblezone {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 45.2242px;
  line-height: 59px;
  color: #000000;
}
.container-list-info-stat-bubblezone {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.info-stat-bubblezone-owner {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13.3812px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 0;
  padding: 15px 0;
}
.info-stat-bubblezone-owner-color {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13.3812px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #db3251;
  margin-bottom: 0;
  padding: 15px 0;
}
.content-info-last-col-stat-detail-bubblezone {
  width: 60%;
}

.text-graph-deatil-bubblezone {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #333333;
}
.text-description-page-sponsor {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 140%;
  color: #2d3748;
}
.title-sponsor-owner {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 140%;
  color: #2d3748;
}
.form-label-form-sponsor {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #06152b;
  display: flex;
}
.form-control-form-sponsor {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  padding: 20px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #ababab;
}
.container-form-contact {
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
.btn-save-form-sponsor {
  background: #db3251;
  border-radius: 0;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: 100%;
  padding: 15px;
}
.btn-save-form-sponsor:hover {
  border: 1px solid #db3251;
  color: #db3251;
  background: #fff;
}
.container-graph-mood > div {
  margin: auto;
}

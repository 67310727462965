.body-background-dashboard {
  /* background: #eff1f7; */
  background: rgb(245, 245, 245);
}
.admin-page-container {
  margin-right: 3%;
  margin-left: 5%;
}
.content-position-after-header {
  /* margin-top: 8%; */
  margin-top: 6%;
}
.title-view-dashboard {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 15px;
}
.card-statistics {
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
}
.icon-card-statistics {
  color: rgba(255, 255, 255, 0.432);
  position: absolute;
  right: 40px;
  top: 20px;
  width: 50px;
}

.chiffre-card-statistics {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 59px;
  color: #ffffff;
}

.titre-card-statistics {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.stat-conteny p {
  color: #ffffff;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}
.parent-label-card-statistics {
  font-weight: 800;
}
.child-label-card-statistics {
  margin-top: 5px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300 !important;
  font-size: 11px !important;
  line-height: 13px;
  color: #ffffff;
}

.item-stat-dashboad-view {
  background: #ffffff;
  padding: 10px 20px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.titre-item-stat-dashboad-view {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16.2131px;
  line-height: 21px;
  color: #747474;
}
.chiffre-item-stat-dashboad-view {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 43.235px;
  line-height: 56px;
  color: #000000;
}
.chart-item-admin-title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}
.content-stat-dashboard-view {
  display: flex;
  align-items: center;
  width: 100%;
}
.content-text-item-stat-dashboad-view {
  width: 100%;
}

.col-left-dashboard {
  padding: 10px 0;
}
.container-table-locataires-non-paye .react-bootstrap-table {
  padding-left: 0;
}
.title-card-dashbord-view {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.litige-card-item-dashboard-view {
  display: flex;
  gap: 1rem;
}
.litige-text-dashboard-view {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
}
.circle-item-green {
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 4px solid #1aa981;
}
.circle-item-blue {
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 4px solid #1f3cad;
}
.circle-item-yellow {
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 4px solid #fcae3b;
}
.vertical-line {
  border-left: 2px solid #b8bed9;
  display: inline-block;
  height: 45px;
  margin: 10px 10px;
}
.title-info-dashboard-view {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.text-date-info-dashboard-view {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #3d3d3d;
}
.text-chiffre-info-dashboard-view {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #1f3cad;
}
.content-info-item {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
}
.content-img-notif-requete svg {
  font-size: 35px;
}
.content-img-notif-requete {
  position: absolute;
  top: 0;
  left: 0;
  background: #fcae3b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px 0px;
  color: #fff;
  padding: 10px;
}
.btn-see-more {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #158a91 !important;
  background: transparent;
  padding: 15px;
  border: none;
}
.btn-see-more:hover {
  text-decoration: none !important;
  border: none;
  color: #158a91;
}
.icon-see-more {
  color: #158a91;
}
.dashboard-view-hiddenable-notification-section {
  position: absolute;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
  top: 56%;
  width: 100%;
}
.dashboard-view-hiddenable-notification-section:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 20px solid #ffffff;
  position: absolute;
  left: 1%;
  top: -5%;
}

.bubble-zone-dash-header-titre {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #11142d;
}
.title-graph-dashboard {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #011f3b;
}
.container-legend-mood-graph {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 5px; */
}

.container-legend-mood-graph::-webkit-scrollbar {
  height: 0px;
}

.container-legend-mood-graph::-webkit-scrollbar-track {
  background: #fff;
}

.pourcentage-mood-detail-dashboard {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 11.2844px;
  line-height: 100%;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}
.content-mood-detail-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.div-color-legend-graph {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.text-legend-graph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #000000;
}
.content-col-legend-hobbies {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* =========================== DASHBOARD V2 =========================== */
.custom-tab-dash-admin-container .custom-tab-dash-admin.active {
  color: #2d3748;
  text-align: center;
  font-size: 14.556px;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 140%;
  background: none;
}
.custom-tab-dash-admin-container .custom-tab-dash-admin.active::after {
  content: "";
  display: block;
  position: relative;
  /* width: 20%; */
  width: 50%;
  border: 2px solid #db3251;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
}
.custom-tab-dash-admin-container .custom-tab-dash-admin {
  color: #2d3748;
  text-align: center;
  font-size: 17.556px;
  font-family: "Poppins";
  line-height: 140%;
}
.custom-tab-dash-admin-container .custom-tab-dash-admin-rs.active {
  color: #2d3748;
  text-align: center;
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 33.6px;
  background: none;
}
.custom-tab-dash-admin-container .custom-tab-dash-admin-rs.active::after {
  content: "";
  display: block;
  position: relative;
  /* width: 20%; */
  width: 50%;
  border: 2px solid #db3251;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
}
.custom-tab-dash-admin-container .custom-tab-dash-admin-rs {
  color: #2d3748;
  text-align: center;
  font-size: 24px;
  font-family: "Poppins";
  line-height: 33.6px;
  font-weight: 400;
}
.custom-item-stat-dashboad-view {
  border-radius: 9.007px;
  background: #fff;

  padding: 10px 20px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nombre-connexion-graph-container,
.meilleure-score-container {
  background: #fff;
  padding: 10px;
}
.custom-mb-negatif {
  margin-bottom: -20px;
}
.custom-centre-iteret-item {
  border-radius: 40px;
  border: 1px solid rgba(219, 50, 81, 0.2);
  background: #fff;
  display: inline-flex;
  padding: 11px 24px;
  align-items: flex-start;
  gap: 10px;
}
.custom-centre-iteret-item-container {
  display: flex;
  align-items: center;
  gap: 12px;
}
.custom-centre-iteret-item-number {
  color: #000;
  font-size: 14px;
  font-family: "Poppins";
  letter-spacing: 0.28px;
}
.taux-conversion-btn-filtre-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.taux-conversion-input-select-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.taux-conversion-btn-action-filter {
  color: #333;
  text-align: center;
  font-size: 14.252px;
  font-family: "Poppins";
  line-height: 27.386px;
  padding: 6.913px;
  background: none;
  border: none;
}
.taux-conversion-btn-action-filter.active {
  color: #db3251;
  text-align: center;
  font-size: 14.252px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 27.386px;
  padding: 6.913px;
  background: none;
  border: none;
}
.taux-conversion-btn-container {
  display: flex;
  align-items: center;
  gap: 12px;
}
.taux-conversion-custom-label {
  color: #000;
  font-size: 15px;
  font-family: "Roboto";
  margin-bottom: 0px;
}
select.taux-conversion-custom-form-select {
  color: #000;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 500;
}
.taux-conversion-card-item-label {
  color: #747474;
  font-size: 15.213px;
  font-family: "DM Sans";
  font-weight: 500;
}
.taux-conversion-card-item-value {
  color: #000;
  font-size: 32px;
  font-family: "DM Sans";
  font-weight: 500;
}
.taux-conversion-card-item-value span {
  color: #747474;
  font-size: 15px;
  font-family: "DM Sans";
  font-weight: 500;
}
.taux-conversion-card-item-container {
  border-radius: 9.007px;
  background: #fff;
  padding: 15px 20px;
}
.taux-click-label {
  color: #333;
  text-align: center;
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 39.617px;
}
.taux-conversion-card-item-value .custom-size-span {
  color: #000;
  font-size: 20px;
  font-family: "DM Sans";
  font-weight: 500;
}
.content-col-right-b2b-component-wrapped {
  /* height: 350px; */
  height: max-content;
  overflow: auto;
}
/* .article-from-google-container {
  height: 135px;
  overflow: auto;
} */
/* =========================== DASHBOARD V2 =========================== */

.calendar-contenaire .example-custom-input {
  background: transparent !important;
  border: none !important;
}

.content-col-right-b2b-component {
  border-radius: 6px;
  background: #faf9f7;
  padding: 20px;
  /* border-left: 1px solid #D9D9D954;
  border-right: 1px solid #D9D9D954; */

}
.text-sub-title-bubblezone-card-graph {
  color: #2d3748;
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-transform: uppercase;
}
.classement-bubblezone-card-graph-title {
  color: #2d3748;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
}

.classement-bubblezone-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #faf9f7;
  padding: 10px;
  margin-bottom: 8px;
}
.classement-bubblezone-item:last-child {
  margin-bottom: 0px;
}

.classement-bubblezone-item-owner {
  background: #f6eff3;
}

.content-text-classement-bubblezone-item {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.content-text-classement-bubblezone-item-owner {
  color: #db3251;
}
.img-list-classement-bubblezone-item {
  width: 35px;
  height: 35px;
  border-radius: 5px;
}
.content-col-classement-list-section2 {
  border-radius: 5px;
  background: #faf9f7;
  padding: 15px;
}

.title-classement-list-section2 {
  color: #2d3748;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
}
.bubble-map-container-admin {
  height: 40vh;
}
.nav-link-setting {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: #000000 !important;
}
.nav-link-setting.active {
  border-bottom: 3px solid #db3251 !important;
  font-weight: 600;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}
.nav-link-setting:hover {
  border-color: transparent !important;
}
.nav-tabs-setting {
  border-bottom: transparent;
}
.title-termes-conditions {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #06152b;
}
.text-conditions-termes {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 209.9%;
  color: rgba(0, 0, 0, 0.7);
  text-align: justify;
}
.container-btn-vote {
  display: flex;
  flex-wrap: wrap;
}
img.img-mood {
  width: 90px;
  height: 90px;
}
/* ============== PARAMETRE V2 =============== */
.custom-reponse-item {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 9.335px 8px 17.736px;
  border: 0.933px solid #db3251;
}
.custom-text-reponse-item {
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
  font-size: 12.07px;
  font-family: "Poppins";
  font-weight: 500;
}
.custom-size-btn-action {
  width: 24px;
  height: 24px;
  font-size: 13px;
}
.custom-size-btn-action:hover {
  width: 24px;
  height: 24px;
  font-size: 13px;
}
.custom-btn-add-response {
  display: flex;
  width: 201px;
  height: 34px;
  padding: 5.601px 9.335px 5.601px 1px;
  justify-content: center;
  align-items: center;
  gap: 13.069px;
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 12.07px;
  font-family: "Poppins";
  border: none;
  border-radius: 0px;
}
.custom-btn-add-response-icon {
  color: #666666;
  font-size: 24px !important;
}
.pourcentage-mood {
  color: #db3251;
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 100%;
}
.btn-add-theme-custom {
  color: #531132;
  font-size: 16px;
  font-family: "DM Sans";
}
.btn-add-theme-custom-icon {
  color: #db3251;
  font-size: 20px;
}
.input-group-text {
  color: #06152b;
  font-size: 16px;
  font-family: "DM Sans";
  font-weight: 500;

  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 0.25px solid #959595;
  border-radius: 0px;
  border-left: none !important;
}
.no-border-right {
  border-right: none !important;
}
.custom-input-select {
  height: 58px;
  border: 0.25px solid #959595 !important;
  border-radius: 0px !important;
}
.param-titre-preference {
  color: #2d3748;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.rs-downloadable-item {
  width: 100%;
  border-radius: 25px;
  background: #d9d9d924;
  padding: 20px;
}
.rs-downloadable-item-image-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.rs-downloadable-item-image {
  width: 51px;
  height: auto;
  border-radius: 10;
}
.rs-downloadable-item-name-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.rs-downloadable-item-name {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}
.rs-downloadable-item-day {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
}
.btn-rs-downloadable-item {
  padding: 10px 20px;
  border-radius: 17px;
  background: #ffffff;
  box-shadow: 0px 4px 15px 0px #0000000a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #db3251;
}
.btn-rs-downloadable-item:hover {
  border-radius: 17px;
  background: #ffffff;
  border: 0.5px solid #db3251;
  color: #db3251;
}
.btn-add-fonctionnalite {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.78px solid #00000026;
  border-radius: 8px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000a6;
  padding: 14px;
}
.btn-add-fonctionnalite-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  width: 87px;
  height: 35px;
  border-radius: 8;
  background: #db3251;
  padding: 28px 35px;
}
.add-fonctionnalite-item {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
}
.custom-fonctionnalite-item {
  width: 50%;
}
.ajout-offrte-btn-annuler {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #121212;
  background: none;
  display: flex;
  align-items: center;
  padding: 14px;
}
.ajout-offrte-btn-submit {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  background: #db3251;
  padding: 14px;
  display: flex;
  align-items: center;
}
/* ============== PARAMETRE V2 =============== */

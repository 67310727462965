.btn-add-theme {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #531132;
  background: transparent;
  padding: 0.5rem 1rem;
}
.btn-add-theme:hover {
  border: 1px solid #db3251;
  color: #db3251;
  background: #fff;
}
.tabs-container {
  display: flex;
  background: #fff;
  padding: 30px 20px 2px 20px;
  margin-top: -16px;
}

.tabs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.tabs-container li {
  list-style: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #000000;
  transition: all ease-in-out 300ms;
  padding-bottom: 10px;
}
li.tab-active-item {
  color: #158a91;
  font-weight: 700;
  border-bottom: 2px solid #158a91;
}
.row-info-perso-user {
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
  padding: 20px;
}
.row-info-perso-user:last-child {
  border-bottom: none;
}
.text-info-user-libelle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  color: #666666;
  margin-bottom: 0;
}
.text-info-user-value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  color: #666666;
  margin-bottom: 0;
}
/* ================= DÉTAILS UTILISATEUR ================== */
.bubblezone-dash-admin-page-content-container {
  border-radius: 10px;
  background: #fff;
  padding: 30px 15px 0px 15px;
}
.custom-tab-dash-admin-container .custom-tab-details-user.active {
  /* text-align: center; */
  background: none;

  color: #860805;
  font-size: 17px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-tab-dash-admin-container .custom-tab-details-user.active::after {
  content: "";
  display: block;
  position: relative;
  width: 100%;
  border: 2px solid #860805;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
}
.custom-tab-dash-admin-container .custom-tab-details-user {
  /* text-align: center; */
  color: #333;
  font-size: 17px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-name-user-admin {
  color: #000;
  font-size: 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.custom-code-user-admin {
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.pp-profil-user-visiteur {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.bubblezone-dash-admin-section-bio-btn-edit {
  padding: 5px 15px;
  background: #dedede;
  border-radius: 0px;
}
.bubblezone-dash-admin-section-edit-profil-item {
  display: flex;
  justify-content: space-between;
}
.bubblezone-dash-admin-section-edit-profil-prenom {
  width: 50%;
}
.bubblezone-dash-admin-section-edit-profil-prenom-item {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  color: #1a1a1a;
}
.bubblezone-dash-admin-section-edit-profil-btn-container {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.bubblezone-dash-admin-section-edit-profil-prenom-item-deux {
  color: #666;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}
.bubblezone-dash-admin-section-edit-profil-btn-item-deux {
  color: #666;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}
/* ================= DÉTAILS UTILISATEUR ================== */
select.custom-v-basic.form-select,
select.custom-v-pro.form-select {
  min-width: 80px !important;
  border: none !important;
  border-radius: 4px;
  text-align: center;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
select.custom-v-pro.form-select {
  background-color: rgba(255, 231, 55, 0.45);
  color: #000;
}
select.custom-v-basic.form-select {
  background-color: #D5D5D5;
  color: #2F070F;
}
.details-user-custom-is-pro {
  background: rgba(255, 231, 55, 0.45);
  color: #000;
}
.details-user-custom-is-basic {
  background-color: #D5D5D5;
  color: #2F070F;
}
.details-user-custom-is-pro,
.details-user-custom-is-basic {
  border-radius: 5.325px;
  text-align: center;
  font-family: "Poppins";
  font-size: 13.314px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 2px 15px;
}
.btn-download-messages:hover {
  color: #860805;
}
.btn-download-messages {
  color: #DB3251;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  background: none;
  border: none;
}
.img-couverture-option-agence {
  width: 100%;
}
.content-btn-edit-img-couverture {
  position: absolute;
  right: 3%;
  top: 10%;
}
.btn-edit-img-option-agence {
  background: #ffffff;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 5%);
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  color: #1f3cad;
}
.container-logo-option-agence {
  background: #ffffff;
  box-shadow: 0px 2.61188px 22.854px rgb(0 0 0 / 8%);
  border-radius: 50%;
  width: 141.62px;
  height: 141.62px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 75%;
  left: 5%;
}
.input-group-personnalisation {
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
}
.input-group-personnalisation input {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 17.1397px;
  line-height: 154.4%;
  color: #828282;
  border: transparent;
}

.form-label-form-custom {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 19.0441px;
  line-height: 154.4%;
  color: #333333;
}

.input-group-text-img {
  border: transparent;
  background: #fff;
}
.container-radio-input {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.form-radio-input {
  display: flex;
  flex-wrap: wrap;
}
.radio-input label {
  margin-left: 10px;
  margin-right: 10px;
}
.radio-input label input {
  position: absolute;
  left: -9999px;
}
.radio-input label input:checked + span {
  background-color: #fff;
  border: 1px solid #12c28d;
  box-sizing: border-box;
  border-radius: 10px;
}
.radio-input label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #12c28d;
}
.radio-input label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 10px;
  transition: 0.25s ease;
  border: 1px solid #e1e1e1;
  height: 59px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #868686;
}
.radio-input label span:hover {
  background-color: #fff;
  border: 2px solid #12c28d;
}
.radio-input label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1em;
  height: 1em;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #666666;
}
.agence-infos-mine-container {
  margin-top: 10% !important;
}
.input-group.input-group-personnalisation input {
  border-radius: 10px !important;
}

.custom-permission-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.content-icon-edit-info-profil {
  position: absolute;
  right: 3%;
  top: 51%;
}
.profile-avatar-container {
  width: 200px !important;
  height: 190px !important;
  overflow: hidden !important;
  position: relative !important;
  margin: 15px auto !important;
  border: 1px solid transparent !important;
  border-radius: 22px !important;
  cursor: pointer !important;
}

.profile-avatar-container img {
  width: 100% !important;
  height: 172px !important;
  display: block !important;
  object-fit: cover !important;
}

.profile-avatar-container span {
  position: absolute !important;
  bottom: -100% !important;
  left: 0 !important;
  width: 100% !important;
  height: 50% !important;
  background: #fff8 !important;
  text-align: center !important;
  font-weight: 400 !important;
  color: #db3251 !important;
  transition: 0.3 ease-in-out !important;
}

.profile-avatar-container span p {
  color: #db3251 !important;
  font-size: 15px;
  font-weight: bold;
}
.profile-avatar-container:hover span {
  bottom: 5% !important;
}
.close-icon-pointe {
  cursor: pointer;
}

.profile-avatar-container span.charge {
  bottom: -15% !important;
}

.profile-avatar-container #file_up {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0 !important;
  cursor: pointer !important;
}
.text-info-profil-admin-libelle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #1a1a1a;
}
.text-info-profil-admin-value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1a1a1a;
}
.btn-edit-info-profil {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1a1a1a;
  background: #dedede;
}
.container-info-profil-admin {
  align-items: center;
  border-bottom: 1px solid #ebebeb;
}
.container-info-profil-admin:last-child {
  border-bottom: inherit;
}

.custom-btn-change {
  position: absolute;
  right: 15px;
  top: 15px;
}
.custom-text-resilier-abonnement {
  color: #db3251;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.custom-text-resilier-abonnement-container {
  display: flex;
  justify-content: flex-end;
  margin-top: -12px;
}
.custom-abonnement-pro {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.nav-items-custom {
  margin-bottom: 20px;
}
.nav-link-vertival-custom {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  position: relative;
  border-radius: 16px;
}

.nav-link-vertival-custom:hover {
  background: #db3251;
  color: #fff !important;
  transition: 0.2s ease;
  border-radius: 16px;
}
.nav-link-vertival-custom.active {
  background: #db3251;
  color: #fff !important;
  border-radius: 16px;
}

.nav-link-vertival-custom.active .svg {
  color: #fff !important;
}
.nav-link-vertival-custom:hover .icon-navbar-vertical {
  color: #fff;
}
.nav-link-vertival-custom svg {
  font-size: 20px;
  margin-right: 15px;
  color: #fff !important;
}
/* .hiddenable-navlink-label {
  display: none;
}
.hoverable:hover span.hiddenable-navlink-label {
  display: inline-block;
}
.navbar-vertical-customisation:hover span.hiddenable-navlink-label {
  display: inline-block;
}

.hiddenable-navlink-label {
  padding-left: 10px;
}
.navbar-vertical-customisation {
  padding: 75px 0 40px 0;
  height: 100%;
  max-width: 25%;
  background-color: #531132;
  position: fixed;
  left: 0;
  right: 0;
  top: 90px;
  z-index: 1031;
  width: max-content;
}

.navbar-vertical-customisation::-webkit-scrollbar {
  width: 3px;
  height: 100%;
}
.navbar-vertical-customisation::-webkit-scrollbar-track {
  background: transparent;
}
.navbar-vertical-customisation::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 3px solid transparent;
}
.nav-item-vertical-custom {
  padding: 10px 25px;
  margin-bottom: 5px;
}
#scrollbar {
  height: 100%;
  overflow-y: auto;
}
#scrollbar::-webkit-scrollbar {
  display: none;
}
.content {
  height: 350px;
}
.collapsed ~ .panel-body {
  padding: 0;
}
.arrow-collapse-costom.collapsed .fa-chevron-down,
.arrow-collapse-costom .fa-chevron-right {
  display: none;
}

.arrow-collapse-costom.collapsed .fa-chevron-right,
.arrow-collapse-costom .fa-chevron-down {
  display: inline-block;
}

i.fa {
  cursor: pointer;
  margin-left: 20px;
}
.card-body-collapsed-sidebar {
  background: transparent;
  border: none;
}
.list-style-none {
  list-style: none;
}
.nav-link:focus,
.nav-link:hover {
  color: inherit;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
} 
.nav-link-vertival-custom-collapsed {
  display: none;
}
.hoverable:hover .nav-link-vertival-custom-collapsed {
  display: inline-block;
}
.arrow-collapse-costom {
  color: #ffffff !important;
}
.info-user-connect-navmenu {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.img-user-connect-navmenu {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.container-info-user-connect-navmenu {
  display: none;
}
.container-user-connect-info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hoverable:hover .container-info-user-connect-navmenu {
  display: flex;
}
.ligne-logo {
  background: #158a9182;
  width: 1px;
}
.btn-logout {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ff4842;
  padding: 10px;
}
.btn-logout:hover {
  background: #ff4842;
  color: #fff;
}
.container-btn-logout {
  display: none;
}
.hoverable:hover .container-btn-logout {
  display: inline-block;
  padding: 10px 35px;
}
*/
.custom-nav-item-vertical-custom,
.custom-nav-item-vertical-custom:hover {
  color: #0f0f0f !important;
  font-size: 11.31px;
  font-family: Poppins;
  font-weight: 500;
  position: relative;
  border-radius: 8.078px;
  background: #f0bc42;
}
.custom-nav-item-vertical-custom svg {
  font-size: 20px;
  margin-right: 15px;
  color: #0f0f0f !important;
}

.pro svg {
  font-size: 18px;
  color: #f0bc42 !important;
}

.free svg {
  font-size: 18px;
  color: #0f0f0f !important;
}


/* =================================================================== */
.nav-link-vertival-custom-admin {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav-link-vertival-custom-admin:hover,
.nav-link-vertival-custom-admin.active {
  transition: 0.2s ease;
  color: #fff !important;
  border-radius: 10px;
  background: #844254;
}
.nav-link-vertival-custom-admin.active:after {
  content: "";
  position: absolute;
  left: -25px;
  width: 10px;
  height: 100%;
  top: 0;
  border-radius: 0px 16px 16px 0px;
}
.nav-link-vertival-custom-admin svg {
  font-size: 20px;
}

#nav_button {
  display: none;
}
.on {
  position: fixed;
  top: 23px;
  left: 170px;
  user-select: none;
  transition: 0.1s;
  width: 45px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: none;
  border-radius: 5px;
}
.on svg {
  color: #531132;
}
.on:active {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
  transition: 0.1s;
}
.nav-item-vertical-custom .with-tooltip::after {
  content: "";
}
#nav_button:checked
  ~ .nav-container
  .navbar-vertical-customisation-v2
  .hoverable
  .with-tooltip-menu {
  position: relative;
}

#nav_button:checked
  ~ .nav-container
  .navbar-vertical-customisation-v2
  .hoverable
  .with-tooltip-menu::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 94%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}

#nav_button:checked
  ~ .nav-container
  .navbar-vertical-customisation-v2
  .hoverable
  .with-tooltip-menu:hover::after {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}

#nav_button:checked
  ~ .nav-container
  .navbar-vertical-customisation-v2
  .hoverable
  .hiddenable-navlink-label {
  display: none;
}
#nav_button:checked
  ~ .nav-container
  .navbar-vertical-customisation-v2
  .hoverable
  .container-info-user-connect-navmenu {
  display: none;
}
#nav_button:checked
  ~ .nav-container
  .navbar-vertical-customisation-v2
  .hoverable
  .container-btn-logout {
  display: none;
}
#nav_button:checked ~ .on {
  left: 60px;
}
#nav_button:checked
  ~ .nav-container
  .navbar-vertical-customisation-v2
  .hoverable
  .container-logo-sidebar-nav {
  display: none;
}
.icon-side-menu {
  color: #fff;
  font-size: 22px;
}
.navbar-vertical-customisation-v2::-webkit-scrollbar {
  width: 3px;
  height: 100%;
}
.navbar-vertical-customisation-v2::-webkit-scrollbar-track {
  background: transparent;
}
.navbar-vertical-customisation-v2::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 3px solid transparent;
}
.nav-item-vertical-custom {
  padding: 10px 12px;
  margin-bottom: 5px;
}
#scrollbar {
  height: 100%;
  overflow-y: auto;
}
#scrollbar::-webkit-scrollbar {
  display: none;
}
.content {
  height: 350px;
}

.list-style-none {
  list-style: none;
}
.nav-link:focus,
.nav-link:hover {
  color: inherit;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}

.container-btn-logout {
  display: inline-block;
}
.border-bottom-theme {
  border-bottom: 1px solid rgba(217, 217, 217, 0.2);
}

.app-title-connect-navmenu {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 33px;
  color: #ffffff;
}
.app-title-connect-navmenu-bold {
  font-weight: 800;
}
.container-app-title-navmenu {
  display: flex;
  justify-content: center;
}

#nav_button:checked
  ~ .nav-container
  .navbar-vertical-customisation-v2
  .hoverable
  .container-info-user-connect-navmenu {
  display: none;
}
.btn-logout {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  height: 22px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.5);
}
.version-view-container {
  color: #f2994a;
}
.btn-logout-icone {
  font-size: 22px;
}

.container-btn-logout {
  display: inline-block;
  padding-left: 12px;
}

.container-info-user-connect-navmenu {
  padding-left: 20px;
}

.nav-items-custom {
  margin-bottom: 100px;
}
/* .nav-link-vertival-custom {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6f767e;
  position: relative;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  gap: 15px;
} */

/* .nav-link-vertival-custom:hover,
.nav-link-vertival-custom.active {
  background: #eaeaea;
  transition: 0.2s ease;
  border-radius: 10px;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
} */

/* .nav-link-vertival-custom.active:after {
  content: "";
  position: absolute;
  left: -25px;
  width: 10px;
  height: 100%;
  top: 0;
  border-radius: 0px 16px 16px 0px;
}
.nav-link-vertival-custom svg {
  font-size: 20px;
} */
.navbar-vertical-customisation-v2 {
  padding: 5px 0 40px 0;
  height: 100%;
  max-width: 300px;
  background-color: #521a2a;
  position: fixed;
  left: 0;
  right: 0;
  top: 60px;
  z-index: 99;
  width: max-content;
  transition: 0.75s;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar-logout-btn {
  background: #db3251;
}
.sidebar-logout-btn:hover {
  background: #eb5757;
  color: #fff !important;
}
.sidebar-logout-btn:hover svg {
  color: #fff;
}
.container-logo-sidebar-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.admin-table table thead tr th {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 35px;
  color: #010235;
}
.admin-table table tbody tr td,
.admin-table table tbody tr td p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.7);
}
.admin-table .table thead tr th,
.admin-table .table tbody tr td {
  text-align: left;
}
.admin-table .table {
  border: transparent;
}
.admin-table table tbody tr {
  background: #faf9f7 !important;
  border-bottom: 15px solid #fff !important;
  --bs-table-bg: #faf9f7 !important;
}
.table > :not(:first-child) {
  border-top: none !important;
}
.table thead tr th,
table tbody tr td {
  padding: 20px 10px !important;
  vertical-align: middle;
}

.react-bootstrap-table table {
  table-layout: auto;
}
.btn-send-rappel {
  background: #1f3cad;
  border-radius: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 154.4%;
  color: #ffffff;
  padding: 12px;
}
.btn-see-list,
.btn-see-list:hover {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1aa981;
  padding: 10px;
}
.btn-action-modal-icon,
.btn-action-modal-icon:hover {
  color: #158a91;
  border: none;
  background: rgba(2, 180, 192, 0.22);
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-modal-icon-download,
.btn-action-modal-icon-download:hover {
  color: #666;
  border: none;
  background: none;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-icon-delete,
.btn-action-icon-delete:hover {
  color: #fa1414;
  border: none;
  background: #fa14141a;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-icon-chat,
.btn-action-icon-chat:hover {
  color: #14ae5c;
  border: none;
  background: #c1e6d3;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-icon-edit,
.btn-action-icon-edit:hover {
  color: #2996e5;
  border: none;
  background: rgba(41, 150, 230, 0.22);
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-select-table-custom {
  background: #bdbdbd;
  border-radius: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
  background: url(../../../../assets/icons/chevron-down.png) 92% 47% / 13px
    no-repeat #bdbdbd !important;
}

.with-tooltip {
  position: relative;
}

.btn-action-modal-icon::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 74%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #158a91;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-icon-delete::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 74%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #fa1414;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-icon-chat::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 74%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #14ae5c;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-icon-edit::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 74%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #2996e5;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.with-tooltip:hover::after {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}

.pagination {
  --bs-pagination-border-width: none !important;
  gap: 10px;
}
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border: none !important;
}
.page-item.active .page-link {
  background: #db3251;
  color: #fff;
}
.page-item .page-link {
  border-radius: 10px;
  border: 1px solid #9e9e9e;
  background: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.7);
}
.statut-comment-btn {
  position: absolute;
  top: -1%;
  right: -1%;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  background: #c71515;
  border-radius: 50%;
  color: #ffffff;
  width: 12px;
  height: 12px;
}
.statut-facture-valide {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  background: #06b696;
  border-radius: 5px;
  color: #ffffff !important;
  padding: 10px;
  white-space: nowrap;
}
.statut-facture-a-traiter {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  background: #d62912;
  border-radius: 5px;
  color: #ffffff !important;
  padding: 10px;
  white-space: nowrap;
}
.profil-user-table {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.switch2,
.switch input {
  width: min-content;
  /* padding: 50px; */
  /*flex*/
  display: flex;
  justify-content: center;
  align-items: center;
  /* Position */
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.switch input {
  display: none;
}
.slider {
  width: 75px;
  height: 28px;
  background-color: #c4c4c48f;
  display: block;
  border-radius: 50px;
  position: relative;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
}

.slider::before {
  content: "OUI";
  width: 30px;
  height: 95%;
  background-color: #21a374;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  transition: transform 0.8s, background-color 2s;
}

input:checked + .slider {
  background-color: #c4c4c4;
}

input:checked + .slider::before {
  content: "NON";
  background-color: #cf3918;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(calc(75px - 24px - 8px));
}

.switch2 .slider {
  border-radius: 3px;
}

.switch2 .slider::before {
  border-radius: 0;
}
.text-gray-etoile {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16.1667px;
  line-height: 21px;
  color: #999999;
}
.photo-bubblezone-table {
  width: 41px;
  height: 41px;
  border-radius: 50%;
}
.text-nom-abonnement-boutique {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 23px !important;
  line-height: 34px;
  color: #db3251 !important;
  margin-bottom: 0;
}
.date-img-mood-abonnement {
  width: 28px;
  height: 28px;
}
.img-jeton-table {
  width: 43px;
  height: 46px;
}
.gerant-version-badge-pro {
  display: flex;
  padding: 1px 10px;
  align-items: flex-start;
  gap: 10px;
  color: #000;
  text-align: center;
  font-size: 10px;
  font-family: "Poppins";
  font-weight: 600;
  border-radius: 4px;
  background: rgba(255, 231, 55, 0.45);
}
.gerant-version-badge {
  display: flex;
  padding: 1px 10px;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-family: "Poppins";
  font-weight: 600;
  border-radius: 4px;
  background: #158a91;
}
.container-statut-version {
  display: flex;
  gap: 15px;
  align-items: center;
}
.statut-version-gearant-pro {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4px;
  background: rgba(255, 231, 55, 0.45);
  padding: 8px;
}
.statut-version-gearant-basic {
  color: #2f070f;
  text-align: center;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4px;
  background: #d5d5d5;
  padding: 8px;
}

.empty-bubble-zone-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
}
.empty-bubble-zone-img {
    width: 100%;
}
.empty-bubble-zone-mes-empty {
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.empty-bubble-zone-cta {
    color: rgba(0, 0, 0, 0.60);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.actu-bubblezone-from-google {
    width: 100%;
    display: flex;
    gap: 14px;
}
.actu-bubblezone-from-google-title {
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #404040;

}
.actu-bubblezone-from-google-description {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    text-align: left;
    color: #000000AB;

}
.actu-bubblezone-from-google {
    /* background: #D9D9D926; */
    padding: 5px;
}
.actu-bubblezone-from-google-image {
    min-width: 137px;
    min-height: 140px;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.actu-bubblezone-logo-google {
    width: 100%;
}
.actu-bubblezone-avis-title {
    color: rgb(85, 85, 85);
    font-family: "Poppins";
    font-size: 20px;
}
.actu-bubblezone-avis-note {
    color: rgb(237, 103, 46);
    font-family: "Poppins";
    font-size: 20px;
}
.actu-bubblezone-avis {
    background: #FFF;
    padding: 20px;
    border-radius: 20px;
    width: 180px;
}
.custom-img-preview-mockup {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.custom-container-img-preview-mockup {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}
.auth-component {
  background: #ebf0f0;
}
.login-page-row {
  margin-top: 10%;
  border-radius: 10px;
  margin-bottom: 4%;
}
.right-side-col {
  background: #fff;
  border-radius: 10px;
}
.login-form-container {
  padding: 120px 60px;
}
.auth-right-side-container {
  padding: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-form-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 47px;
  text-align: center;
  color: #000000;
  font-size: clamp(30px, 1vw, 36px);
  white-space: nowrap;
}

.auth-form-group-login {
  width: 100%;
  background: #f7f7f7;
  border-radius: 10px;
}

.auth-form-control {
  background: #f7f7f7;
  border-color: #f7f7f7 !important;
  padding: 25px 15px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.6);
}

.input-group-text-custom {
  background: #F6F6F6 !important;
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 50px;
  color: #c4c4c4 !important;
  border: none !important;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  text-align: center;
  white-space: nowrap;
}
.form-group.auth-form-group {
  margin-bottom: 40px;
}

.forget-password-link {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  color: #711241;
  text-decoration: none;
}
.forget-password-link:hover {
  text-decoration: none;
  color: #db3251;
  font-weight: bold;
}
.auth-form-message {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #333333;
}

.auth-submit-btn {
  background: #db3251;
  width: 100%;
  padding: 15px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  text-align: center;
  border-radius: 0;
}
.auth-submit-btn:hover {
  background: #fff;
  color: #db3251;
  border: 1px solid #db3251;
  text-decoration: none;
}
.auth-left-side-container {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-left-side-col {
  background: linear-gradient(90deg, #02b4c0 0%, #158a91 100%);
}

.auth-row.row .auth-col {
  padding: 0 !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.fixed-after-header {
  margin-top: 4%;
}
.bubblezone-auth-link-register {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-family: "DM Sans";
}
.bubblezone-auth-link-register-span {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-family: DM Sans;
  font-weight: 700;
  text-decoration: none;
}
.bubblezone-auth-link-register-span:hover {
  color: #db3251;
}
.auth-form-title-subtitle {
  color: #333;
  text-align: center;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 300;
  line-height: 24.117px;
  text-align: center;
}
.custom-register-form-container {
  padding: 120px 60px;
  width: 62%;
  margin: auto;
}
.custom-label-remember-me {
  color: #828282;
  font-family: "DM Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
span.span-eye-password-log {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 5;
}
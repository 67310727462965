.empty-comment {
    border-radius: 10px;
    background: #FFF;
    padding: 30px;
}
.empty-comment-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.empty-comment-title {
    color: #2D3748;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
}
.empty-comment-img {
    width: 100%;
}
.empty-comment-message {
    color: #2D3748;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
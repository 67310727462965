.bubblezone-version-pro-pricing {
    color: #DB3251;
    font-size: 60px;
    font-family: Poppins;
    line-height: 150%;
}
.bubblezone-version-pro-pricing span {
    font-size: 30px;
    line-height: 150%;
}
.bubblezone-version-pro-list-item li {
    color: #4F4F4F;
    font-family: "Roboto";
    font-size: 15.037px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.555px;
    list-style-image: url("../../../../assets/icons/check-circle-gray.svg");
    margin-bottom: 10px;
}
.bubblezone-version-pro-list-item li::marker {
    position: absolute;
    top: 10px;
}
.bubblezone-message-felicitation-title {
    color: #000;
    text-align: center;
    font-size: 48px;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 39.617px;
}
.bubblezone-message-felicitation-message {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-family: "Poppins";
    line-height: 40.617px;
}
.bubble-zone-dash-header-titre-offre {
    color: #2D3748;
    font-family: "Poppins";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}
.bubble-zone-offre-card-pro-accroche {
    color: #A1A1AA;
    font-family: "IBM Plex Sans";
    font-size: 15.037px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.555px;
}
.bubble-zone-offre-card-pro-pricing span {
    color: #4F4F4F;
    font-family: "IBM Plex Sans";
    font-size: 15.037px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.555px;
}
.bubble-zone-offre-card-pro-pricing {
    color: #4F4F4F;
    font-family: "Poppins";
    font-size: 56.388px;
    font-style: normal;
    font-weight: 400;
    line-height: 62.027px;
}
.bubble-zone-offre-card-pro-type {
    color: #4F4F4F;
    font-family: "IBM Plex Sans";
    font-size: 16.916px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.194px;
}
.bubble-zone-offre-card-pro {
    border: 0.94px solid rgba(0, 0, 0, 0.16);
    background: #FFF;
    box-shadow: 0px 3.759px 15.037px 0px rgba(0, 0, 0, 0.07);
    padding: 18px 12px;
}




.bubble-zone-offre-card-premium-accroche {
    color: #FFF;
    font-family: "IBM Plex Sans";
    font-size: 15.037px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.555px; 
}
.bubble-zone-offre-card-premium-pricing span {
    color: #FFF;
    font-family: "IBM Plex Sans";
    font-size: 15.037px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.555px;
}
.bubble-zone-offre-card-premium-pricing {
    color: #FFF;
    font-family: "Poppins";
    font-size: 56.388px;
    font-style: normal;
    font-weight: 400;
    line-height: 62.027px; 
}
.bubble-zone-offre-card-premium-type {
    color: #FFF;
    font-family: "IBM Plex Sans";
    font-size: 16.916px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.194px;
}
.bubble-zone-offre-card-premium {
    min-height: 520px;
    border-radius: 6px;
    border: 0.94px solid rgba(0, 0, 0, 0.16);
    background: #DB3251;
    box-shadow: 0px 3.759px 15.037px 0px rgba(0, 0, 0, 0.07);
    padding: 18px 12px;
}
.bubblezone-version-premium-list-item li {
    color: #FFF;
    font-family: "Roboto";
    font-size: 15.037px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.555px;
    list-style-image: url("../../../../assets/icons/check-circle-white.svg");
    margin-bottom: 10px;
}
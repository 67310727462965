.bubblezone-annonce-label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bubblezone-annonce-label-date-heure {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-family: "Poppins";
    line-height: 24px;
    letter-spacing: 0.16px;
}
.bubblezone-annonce-input-date-container {
    display: flex;
    align-items: center;
    gap: 12px;
}
.custom-input-modal-form-add {
  padding: 8px 17px 10px 14px !important;
  border-radius: 5px !important;
    border: 0.5px solid #000 !important;
    height: auto !important;
    background: #FFF;
}
.input-modal-form-add-custom {
    background: rgba(246, 246, 246, 0.56);
    border: none !important;
    border-radius: 2px !important;
}
.input-modal-form-add-custom::placeholder {
    color: #000;
    font-size: 13px;
    font-family: "Poppins";
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.13px;
}
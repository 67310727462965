.title-page-questions-reponses {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.text-question-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #db3251;
}
.content-col-question {
  display: flex;
  gap: 10px;
}
.reponse-item {
  border: 1px solid #db3251;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 10px;
  flex-wrap: wrap;
}
.text-reponse-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12.07px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.8);
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}
.btn-add-response {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.8);
  background: #f2f2f2;
  border: 1px solid #db3251;
  padding: 15px;
  text-align: center;
  width: 100%;
}
.btn-add-response svg {
  font-size: 20px;
}
.btn-add-response:hover {
  background: #db3251;
  color: #fff;
}
.admin-message-user-card-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  color: #db3251;
}
.container-mood-item {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
  padding: 20px;
  width: 100%;
}
.text-pourcentage-mood {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  color: #db3251;
}
.name-mood {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #000000;
}

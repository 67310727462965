body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 320px) and (max-width: 480px) {
  .couverture-title {
    padding: 0 !important;
  }
  .page-couverture-actions-container {
    flex-direction: column;
  }
  .bubble-zone-btn-success {
    letter-spacing: 0 !important;
  }
  .subscribe-newsletters-btn-container,
  .play-video-btn-container {
    margin: 15px !important;
  }
  .bubble-zone-btn-default {
    text-align: center;
  }
  .play-video-btn-container {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1900px) {
  .couverture-title {
    font-size: 100px !important;
    line-height: 110px !important;
  }
  .coming-soon-textual {
    font-size: 55px !important;
  }
  .page-couverture-title {
    margin: 30px 15px !important;
  }
  .bubble-zone-btn-success {
    padding: 35px 40px !important;
    font-size: 30px !important;
  }
  .bubble-zone-btn-default {
    padding: 35px 40px !important;
    font-size: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .play-video-icon {
    width: 100px !important;
    height: 100px !important;
    display: flex !important;
    padding: 25px !important;
  }
  .subscribe-newsletters-btn-container {
    display: flex !important;
    align-items: center !important;
  }
}
.custom-pagination-component {
  padding-left: 3%;
  padding-right: 2%;
}
.bubble-zone-tabs-container .tabs-container {
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 2%;
}
.bubble-zone-tabs-container {
  margin-left: 1%;
}
.bubble-zone-locataire-histo-row {
  margin-left: 1%;
}
.msg-user-infos-container {
  padding-left: 15px;
}

.display-color {
  width: 30px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.badge {
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  background-color: #db3251;
}
.auth-submit-btn-login-short {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  padding: 16px 58px !important;
  gap: 10px !important;
  height: 50px !important;
  background: #db3251 !important;
  border-radius: 10px !important;
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #ffffff !important;
}
.auth-submit-annuler {
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #121212 !important;
}
.add-bubble-heure-dispo-container input[type="time"] {
  border: none;
}

.blured {
  filter: blur(6px) !important;
  -webkit-filter: blur(6px) !important;
}

.map {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: -10px;
  cursor: pointer;
}

.container-dashboard-page,
.filter-and-table-container,
.questions-reponses-component,
div#arguments,
div#mood,
div#offre,
div#termes-conditions,
div#ptc,
div#admins {
  border-radius: 10px;
}
.upgrade-modal,
.custom-backdrop-update {
  top: 94px;
  left: 202px;
  width: calc(100% - 202px);
  height: calc(100% - 94px);
  z-index: 1000;
}
.preview-modal,
.custom-backdrop-preview {
  background: none;
}
.upgrade-version-modal-container {
  padding: 18px;
  gap: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.upgrade-version-modal-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 24px;
  line-height: 33.6px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
.custom-nav-item-vertical-custom.modal-upgrade {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.custom-input-date-select {
  width: 100% !important;
  height: 46px !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.custom-blur-effect {
  filter: blur(1.5px);
}
.custom-brn-debloque-when-disable {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
}
.custom-btn-debloquer-maintenant {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 18px;
  gap: 10px;
  background: #db3251;
  border-radius: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: #ffffff;
  z-index: 2;
  width: max-content;
  box-shadow: 2px 2px 8px 4px rgba(128, 127, 127, 0.51);
}
.custom-text-debloquer-maintenant {
  text-shadow: 2px 2px 8px rgba(128, 127, 127, 0.51);
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}
.custom-cursor-disabled {
  cursor: not-allowed;
}
.admin-page-title-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message-item-without-input-container {
  overflow: auto;
}
.custom-communication-preview.d-flex.align-items-center.justify-content-center.h-100.w-100.m-auto.rounded.p-1 {
  width: 100% !important;
  height: 50% !important;
}
.custom-size-for-preview {
  height: 500px;
}
.custom-group-input-group-text {
  font-family: "Poppins";
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  color: #00000099;
}
.custom-text-bold {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  background: #000000cc;
}
.add-offr-list-fonctionnalite {
  padding: 12px;
  width: 100%;
  min-height: 365px;
  height: max-content;
  border-radius: 10px;
  border: 1px solid #0000000f;
}
.custrom-pos-btn-actions {
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.icon-edit-fonctionnalite {
  width: 15px;
  height: 15px;
}
.btn-edit-fonctionnalite {
  background: none;
  border: none;
  padding: 4px;
}
.no-scroll {
  overflow: hidden;
}